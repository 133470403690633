/**
 * HeaderIcon displays a countdown to an event
 */
import {LitElement, html} from 'lit-element';

class HeaderIcon extends LitElement {

    constructor() {
        super();
        this.cdn = '';
        this.firstName = '';
        this.lastName = '';
        this.picture = '';

        this.getUserProfile();
    }

    createRenderRoot() {
        return this;
    }

    static get properties() {
        return {
          cdn: { type: String },
          firstName: { type: String },
          lastName: { type: String },
          picture: { type: String },
        };
    }

    getUserProfile() {
      // get user profile
      fetch('/user/me', {
          method: 'get',
          headers: {
              'Content-Type': 'application/json'
          }
      })
        .then( response => response.json() )
        .then( data => {
          // console.debug('user', data);
          this.firstName = data.first_name;
          this.lastName = data.last_name;
          this.picture = data.picture;
        } )
        .catch((e) => {
            console.debug('Error', e);
        });
    }

    render() {
        return html`
          <span class="text color-secondary user-name">${this.firstName}</span>
          <img alt="User Avatar1" style="width: 2rem; border-radius: 100%; margin-left: 0.5rem;" src=${this.picture ? this.picture : `${this.cdn}/generic/img/${ window.constants.client }/header/icon-profile.svg`} />
        `;
    }
}

customElements.define('header-icon', HeaderIcon);

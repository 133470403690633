/**
 * ConvertToTimeElement converts an ISO date string into a user friendly text using the timezones provided
 */
import {LitElement, html} from 'lit-element';

class ConvertToTimeElement extends LitElement {

    constructor() {
        super();
        // The ISO date provided
        this.dateString = '';
        // User friendly text displayed on the screen
        this.text = '';
        // The timezones provided that should show in the text
        this.timezones = [];
    }

    static get properties() {
        return {
            dateString: { type: String },
            text: { type: String },
            timezones: { type: Array }
        };
    }

    connectedCallback() {
        super.connectedCallback();
        this.convertToTimezones();
    }

    // Converts the ISO into the timezones provided
    convertToTimezones() {
        // Get the time section of the string
        const time = this.dateString.split('T')[1];
        // Split by hours and minutes
        const number = time.split(':');
        // Create time text for each timezone
        const times = this.timezones.map( (timezone) => {
            // Check if time difference causes the next day
            if ( parseInt(number[0]) + timezone.difference > 23) {
                const hours = parseInt(number[0]) + timezone.difference - 23;
                // Convert to HH:MM Code
                return `${ ('0' + (hours)).substr(-2) }:${number[1]} ${timezone.code}`;
            }

            // Check if time difference causes the previous day
            if( parseInt(number[0]) + timezone.difference < 0 ) {
                const hours = parseInt(number[0]) + timezone.difference + 23;
                // Convert to HH:MM Code
                return `${ ('0' + (hours)).substr(-2) }:${number[1]} ${timezone.code}`;
            }
            
            // Time difference doesn't cause a different day
            const hours = parseInt(number[0]) + timezone.difference;
            // Convert to HH:MM Code
            return `${ ('0' + (hours)).substr(-2) }:${number[1]} ${timezone.code}`;
        }, '' );

        // Join all timezones by / to create one string
        this.text = times.join(' / ');
    }

    render() {
        return html`${this.text}`;
    }
}

customElements.define('convert-to-time-element', ConvertToTimeElement);

/**
 * Cookie Service Class handles cookies used on Ford Horizon.
 * Helper classes are provided to get and set Cookies
 */
class CookieService {

    constructor() {}

    // Given a name, returns the cookie set. Returns null if it's not found
    getCookie(name) {
        if (!document.cookie || document.cookie.indexOf(`${name}=`) < 0) {
            return null;
        }

        return document.cookie
            .split('; ')
            .find(row => row.startsWith(`${name}=`))
            .split('=')[1];
    }

    // Sets a cookie, the key is the name provided, value is the value of the cookie
    // Options contains any additional settings (optional)
    setCookie(key, value, options) {
        let opts = options || {};
        const now = new Date();
        // Get time 6 months from now
        let sixMonths = new Date();
        sixMonths.setMonth(now.getMonth() + 6);

        opts.expires = opts.expires || sixMonths.toUTCString();
        opts.SameSite = opts.SameSite || 'Lax';
        opts.path = opts.path || '/';
        // this breaks on localhost
        // TODO Fix this for Lincoln
        opts.domain = opts.domain || '.fordhorizon.com';

        let cstring = `${key}=${value}`;
        Object.keys(opts).forEach(key => {
            cstring += `; ${key}=${opts[key]}`;
        });
        document.cookie = cstring;
    }
}

window.cookieHelper = new CookieService();

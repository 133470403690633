/**
 * MenuElement controls when to show content in a menu
 * The header (the button text which is pressed to turn menu on and off) - is inserted using heading slot
 * The menu (the section that appears on toggle) is inserted using the menu slot
 */
import {LitElement, html, css} from 'lit-element';

class MenuElement extends LitElement {

    constructor() {
        super();
        // Whether the menu is in the active state
        this.active = false;
        // Screen Reader Label
        this.ariaLabel = '';
        // Whether an arrow should display after the header slot
        this.arrow = false;
        // Color of the arrow - solve this with CSS
        this.color = '';

        // Bind event to the this scope so can access class variables
        this.handleClick = this.handleClick.bind(this);
    }

    static get properties() {
        return {
            active: { type: Boolean },
            ariaLabel: { type: String },
            arrow: { type: Boolean },
            color: { type: String },
        };
    }

    static get styles() {
        return css`
            .menu-trigger {
                border: none;
                background: transparent;
                padding: 0;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                cursor: pointer;
            }

            .nav-item {
                display: flex;
                align-items: center;
                cursor: pointer;

                &.margin {
                    margin-right: 1rem;
                }

                .icon {
                    margin-left: 0.5rem;
                }
            }

            .arrow {
                width: 0.9375rem;
                object-fit: contain;
                object-position: center;
                margin-left: 0.5rem;
                transition: transform 0.25s ease;
            }
            .arrow.rotated {
                transform: rotate(180deg);
            }
        `;
    }

    // Returns all items in the menu - that are focusable
    get menuItems() {
        return this.querySelector('[role="menu"]').querySelectorAll('[role="menuitem"]');
    }

    // Toggles the menu on and off
    // When on - add listener to detect for clicks (allows close if user presses elsewhere)
    toggleMenu(value) {
        this.active = value;

        setTimeout( () => {
            if (this.active) {
                // Check if touch device
                if ('ontouchstart' in window) {
                    window.addEventListener('touchstart', this.handleClick);
                } else {
                    window.addEventListener('click', this.handleClick);
                }
            } else {
                // Check if touch device
                if ('ontouchstart' in window) {
                    window.removeEventListener('touchstart', this.handleClick);
                } else {
                    window.removeEventListener('click', this.handleClick);
                }
            }
        });
    }

    // Handle user clicks when menu is active - if they click outside the menu, close menu
    handleClick(event) {
        if (!this.checkIsInContent(event.target)) {
            this.toggleMenu(false);
        }
    }

    // Detect whether a click event happened inside the menu
    // Current bug where user can press another menu and this will return true
    checkIsInContent(targetElement) {
        let isInContent = false;
        while (targetElement && !isInContent) {
            isInContent = targetElement.nodeName === 'MENU-ELEMENT';
            targetElement = targetElement.parentElement;
        }
        return isInContent;
    }

    // Handle keyboard navigation of the menu element
    handleKeyPress(event) {
        // If ESC key is pressed - close the menu
        if(event.keyCode == '27') {
            this.toggleMenu(false);
            this.shadowRoot.querySelector('#menu-trigger').focus();
        }

        // Handle up and down arrows on keyboard - Focus on elements up and down in the list
        if ( event.target.getAttribute('role') === 'menuitem' ) {
            // Get index of current menu item
            const menuItemIndex = Array.from(this.menuItems).indexOf(event.target);

            // Handle down arrow press
            if (event.keyCode == '40') {
                event.preventDefault();
                // Find the next item in the menu
                let nextItemElement = this.menuItems[ parseInt(menuItemIndex) + 1 ];

                // Check if next item otherwise get first item
                if (nextItemElement) {
                    nextItemElement.focus();
                } else {
                    const firstItem = this.menuItems[0];
                    firstItem.focus();
                }
            }

            // Handle up arrow press
            if(event.keyCode == '38') {
                event.preventDefault();
                // Find the previous item in the menu
                let previousItemElement = this.menuItems[ parseInt(menuItemIndex) - 1 ];

                // Check if a previous element otherwise get last item
                if (previousItemElement) {
                    previousItemElement.focus();
                } else {
                    const lastItem = this.menuItems[this.menuItems.length - 1];
                    lastItem.focus();
                }
            }
        }
    }

    render() {
        return html`
            <li
                role="menuitem"
                class="nav-item"
                @keydown="${this.handleKeyPress}"
                aria-label="${this.ariaLabel}"
                aria-haspopup="true"
                aria-expanded="${this.active ? 'true' : 'false'}">

                <button
                    id="menu-trigger"
                    class="menu-trigger"
                    @click=${ () => this.toggleMenu(!this.active) }>

                    <slot name="heading"></slot>
                    ${ this.arrow ? ( window.constants.client === 'ford' ? html`
                        <img class=${this.active ? "arrow rotated" : "arrow"} src="${window.constants.cdn}/generic/img/${ window.constants.client }/arrows/arrow-down-grey.svg" />
                    ` : html`
                        <img class=${this.active ? "arrow rotated" : "arrow"} src="${window.constants.cdn}/generic/img/${ window.constants.client }/arrows/arrow-down-black.svg" />
                    ` ) :'' }

                </button>

                ${ this.active ? html`<slot name="menu"></slot>` : '' }

            </li>
        `;
    }
}

customElements.define('menu-element', MenuElement);

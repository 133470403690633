import '@webcomponents/webcomponentsjs/webcomponents-loader';
import '@webcomponents/webcomponentsjs';

// Classes
import './classes/constants.js';
import './classes/analytics.js';
import './classes/controller.js';
import './classes/cookie.js';
import './classes/copy.js';
import './classes/language.js';
import './classes/working.js';

// Utils
import './utils/date.js';

// Elements
import './ui-elements/menu.js';
import './ui-elements/header-icon.js';
import './ui-elements/icons.js';
import './ui-elements/carousel.js';
import './ui-elements/convertToTime.js';
import './ui-elements/countdown.js';
import './ui-elements/cookiebar.js';
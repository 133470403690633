/**
 * Constants Class stores common variables so they can be accessed from components without having to be passed through in the attributes
 */
 class Constants {

    constructor() {
        this._brand = '';
        this._show = '';
        this._eventId = '';
        this._cdn = '';
        this._user = {
            id: '',
            firstName: '',
            lastName: ''
        }
        this._language = '';
        this._eventApi = '';
        this._eventApiToken = '';
        this._client = '';
    }

    get brand() {
        return this._brand;
    }

    set brand(value) {
        this._brand = value;
    }

    get show() {
        return this._show;
    }

    set show(value) {
        this._show = value;
    }

    get eventId() {
        return this._eventId;
    }

    set eventId(value) {
        this._eventId = value;
    }

    get cdn() {
        return this._cdn;
    }

    set cdn(value) {
        this._cdn = value;
    }

    get user() {
        return this._user;
    }

    get userId() {
        return this._user.id;
    }

    set userId(value) {
        this._user.id = value;
    }

    get userFirstName() {
        return this._user.firstName;
    }

    set userFirstName(value) {
        this._user.firstName = value;
    }

    get userLastName() {
        return this._user.lastName;
    }

    set userLastName(value) {
        this._user.lastName = value;
    }

    get language() {
        return this._language;
    }

    set language(value) {
        this._language = value;
    }

    get eventApi() {
        return this._eventApi;
    }

    set eventApi( value ) {
        this._eventApi = value;
    }

    get eventApiToken() {
        return this._eventApiToken;
    }

    set eventApiToken( value ) {
        this._eventApiToken = value;
    }

    get client() {
        return this._client;
    }

    set client( value ) {
        this._client = value;
    }

    onInit(values = {}) {
        if (values.brand) {
            this.brand = values.brand;
        }
        if (values.show) {
            this.show = values.show;
        }
        if (values.eventId) {
            this.eventId = values.eventId;
        }
        if (values.cdn) {
            this.cdn = values.cdn;
        }
        if(values.userId) {
            this.userId = values.userId;
        }
        if(values.userFirstName) {
            this.userFirstName = values.userFirstName;
        }
        if(values.userLastName) {
            this.userLastName = values.userLastName;
        }
        if(values.language) {
            this.language = values.language;
        }
        if(values.eventApi) {
            this.eventApi = values.eventApi;
        }
        if(values.eventApiToken) {
            this.eventApiToken = values.eventApiToken;
        }
        if(values.client) {
            this.client = values.client;
        }
    }
}

window.constants = new Constants();
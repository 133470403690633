/**
 * Controller Service Class handles dates on the Event Main page
 * The controller works out the time offset between the users browser and server time (given a timestamp for the server)
 * Given a list of dates (each date consists of start and end ISO string) to track it will return updates for when a date changes status (pre - before event, active - between start and end date, and post - after the end date)
 * When a status changes, the callback is triggered with the new status
 * event-main.hbs handles the responses to show the correct content
 */
class ControllerService {

    constructor() {
        // Holds the interval that repeatedly checks for status changes
        this.interval = null;
        // The offset in milliseconds between the user's browser's time and server time
        this.offset = 0;
        // The list of dates to track, each element in array holds:
        // name (an id for the tracker), currentStatus (pre/active/post), start (Start date in ISO), end (End date in ISO), callback (function fired when status changes)
        this.tracking = [];
    }

    // Gets the offset between the user's browser's time and the server time
    // The time parameter is set from the template (provided by the server)
    // There will be a slight delay (about 100ms between the template param being set and the js running), however, as this doesn't need to be accurate to the ms - it is not an issue
    getOffset(time) {
        const userDate = new Date().toISOString();
        const diff = new Date(userDate) - new Date(time);
        this.offset = diff;
    }

    // Given 2 dates will return the current status
    getCurrentStatus(start, end) {
        // check if correct format
        if (start == '') return;

        //all dates are UTC but sometimes the timezone will not be added to the
        //end of the string. Additionally, the backend is not smart about
        //parsing dates and leaves it up to the template engine or just forwards
        //string ISO dates. We need to make sure that the Z is on the end as JS
        //only uses a subset of the ISO 8601 date format and requires this to be
        //present.
        //
        //This code is brittle as we are making assumptions about the date
        //format that could break in future if dates begin to be stored in
        //timezones outside of UTC, however right now, storing dates in anything
        //other than UTC is a bug.
        const addUtcZone = (dateString) =>
            dateString.slice(-1) === 'Z' ? dateString : `${dateString}Z`;

        const startZ = addUtcZone(start);
        const endZ = addUtcZone(end);

        const localDbStartDate = new Date(startZ);
        const now = new Date();

        if(now.getTime() < localDbStartDate.getTime()) {
            const tenMinsBefore = new Date(localDbStartDate.getTime() - 10 * 60 * 1000);
            if(now.getTime() > tenMinsBefore.getTime()) {
                return 'soon';
            }
            return 'pre';
        } else {
            const localDbEndDate = new Date(endZ);
            if (now.getTime() > localDbEndDate.getTime()) {
                return 'post';
            } else {
                return 'active';
            }
        }
    }

    // Starts watching all the dates provided. If a date changes status, it fire the callback
    startWatch() {
        this.interval = setInterval( () => {
            this.tracking.forEach( (tracker) => {
                const newStatus = this.getCurrentStatus(tracker.start, tracker.end);
                if (newStatus !== tracker.currentStatus) {
                    tracker.currentStatus = newStatus;
                    tracker.callback(newStatus);
                }
            } )
        }, 1000 );
    }

    // Add a new tracker to the list to watch
    setupTracker(name, start, end, currentStatus, callback) {
        this.tracking.push({name, start, end, currentStatus, callback});
    }

    // On bootup, find the offset between browser and server and start watching for updates
    onInit(time) {
        this.getOffset(time);
        this.startWatch();
    }
}

window.controller = new ControllerService();

/**
 * CookiebarElement displays a cookie information bar at the bottom of the page,
 * that requires the user to accept to remove. Accepting creates a cookie.
 * If the user already has this cookie (already accepted) - don't show the bar
 */
import {LitElement, html} from 'lit-element';

class CookiebarElement extends LitElement {
    
    constructor() {
        super();
        this.active = true;
        this.button = '';
        this.description = '';
        this.link = '';
    }

    static get properties() {
        return {
            active: { type: Boolean },
            button: { type: String },
            description: { type: String },
            link: { type: String }
        };
    }

    createRenderRoot() {
        /**
         * Render template without shadow DOM. Note that shadow DOM features like
         * encapsulated CSS and slots are unavailable.
         */
        return this;
    }

    // On load, check if user has already accepted cookies
    connectedCallback() {
        super.connectedCallback();
        this.checkCookie();
    }

    // Check if the user has already accepted cookies
    checkCookie() {
        const cookie = window.cookieHelper.getCookie('cookieconsent');
        if (cookie === 'ok') {
            this.active = false;
        }
    }

    // User has accepted Cookies, set a cookie and hide the bar
    accept() {
        window.cookieHelper.setCookie('cookieconsent', 'ok');
        // Hide the cookie bar now the user has accepted
        this.active = false;
    }

    render() {
        return this.active ? html`
            <div class="cookiebar">
                <p class="text">${this.description} <a class="color-link" href="/cookie-policy">${this.link}</a></p>
                <button 
                    type="button"
                    class="accept"
                    @click=${ () => this.accept(true) }>${this.button}</button>
            </div>
        ` : '';
    }
}

customElements.define('cookiebar-element', CookiebarElement);

/**
 * CarouselElement displays a list of items and converts them into a carousel
 * The items are provided using slots allowing the items to be styled outside the Shadow DOM
 * The active carousel item is given an 'active' class
 * The item at -1 to the active 'left', The item at 1 to active 'right
 * Items less than -1 are given 'hidden-left', Items more than 1 are given 'hidden-right'
 * The CSS defines how these are handled and are independent of the element (carousel.scss)
 */
import {LitElement, html, css} from 'lit-element';

class CarouselElement extends LitElement {

    constructor() {
        super();
        // The active item
        this.active = 0;
        // The control id used for screen readers
        this.ariaControlId = '';
        // Label used for screen readers
        this.ariaLabel = '';
        // The items provided in the slot
        this.childElements = [];
        // add classes to custom style
        this.classes = '';
    }

    //Note: additional lincoln-specific styles in carousel.scss
    static get styles() {
        return css`
            .carousel {
                width: 100%;
            }
            .carousel-content {
                position: relative;
                width: 100%;
            }
            .arrow {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 3.125rem;
                width: 3.125rem;
                position: absolute;
                top: calc(22% - 2.5rem);
                background: transparent;
                border: none;
                cursor: pointer;
                z-index: 1;
            }
            .arrow.left {
                left: 0;
            }
            .arrow.right {
                right: 0;
            }
            .arrow:disabled {
                opacity: 0.5;
                pointer-events: none;
            }
            .arrow-icon {
                width: 35%;
            }
            .carousel-thumbnails {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin: 2rem 0 1.5rem;
            }
            @media screen and (min-width: 600px) {
                .arrow {
                    top: calc(42% - 2.5rem);
                }
            }

            /* Custom Styling */
            .event-assets-carousel .carousel-thumbnails {
                display: none;
            }
            .event-assets-carousel .arrow {
                top: 40%;
            }
            .event-assets-carousel .arrow.left {
                left: -10px;
            }
            .event-assets-carousel .arrow.right {
                right: -10px;
            }
            @media screen and (min-width: 750px) {
                .event-assets-carousel .arrow {
                    top: 43%;
                    transform: scale(1.5);
                }
                .event-assets-carousel .arrow.right {
                    right: -60px;
                }
                .event-assets-carousel .arrow.left {
                    left: -60px;
                }
            }
        `;
    }

    static get properties() {
        return {
            active: { type: Number },
            ariaControlId: { type: String },
            ariaLabel: { type: String },
            childElements: { type: Array },
            classes: { type: String }
        };
    }

    // Allow the carousel to be controlled using the keyboard
    handleKeyPress(event) {
        // Check we have elements to scroll through
        if (this.childElements.length === 1) {
            return;
        }
        // Left arrow key - trigger slide left
        if (event.keyCode == '37') {
            this.changeSlide(-1);
        }
        // Right arrow key - trigger slide right
        if (event.keyCode == '39') {
            this.changeSlide(1);
        }
    }

    // Changes the active item - prevent moving to slide that doesn't exist
    changeSlide(value) {
        // If at first or last element, check that next item exists
        if (this.active + value < 0 || this.active + value > this.childElements.length - 1) {
            return;
        } else {
            // Change active item
            this.active = this.active + value;
            // Apply new classes to the slotted items
            this.setChildProperties(true);
            // Dispatch change event
            const event = new Event('change');
            this.dispatchEvent(event);
        }
    }

    // Applies the appropriate classes to the slotted items that have been provided
    setChildProperties(shouldFocus) {
        // Loop through the items provided in the slots
        this.childElements.forEach( (child, index) => {

            // Make non active slides non keyboard focusable
            const linkElement = child.querySelector('a');
            let href = '';
            if (linkElement) {
                // Store the anchor link to be applied if active
                href = linkElement.getAttribute('data-url')
                linkElement.removeAttribute('href');
            }

            // If the item is more than 1 to the left of the active item, apply the 'hidden-left' class
            if (index < (this.active - 1)) {
                child.classList.remove('left', 'active', 'right', 'hidden-right');
                child.classList.add('hidden-left');
            }

            // If the item is 1 to the left of active item, apply 'left' class
            if (index === (this.active - 1)) {
                child.classList.remove('hidden-left', 'active', 'right', 'hidden-right');
                child.classList.add('left');
            }

            // If the item is the active item apply the 'active' class, set href to be accessed by keyboard
            if (index === this.active) {
                child.classList.remove('hidden-left', 'left', 'right', 'hidden-right');
                child.classList.add('active');

                // Check anchor exists
                if (linkElement) {
                    linkElement.setAttribute('href', href)
                }
                // Get header element
                const header = child.querySelector('h3');
                // If header exists and focus is enabled
                if (header && shouldFocus) {
                    // wait for element to scroll into view
                    setTimeout( () => header.focus(), 300);
                }
            }

            // If the item is 1 to the right of active item, apply 'right' class
            if (index === (this.active + 1)) {
                child.classList.remove('hidden-left', 'left', 'active', 'hidden-right');
                child.classList.add('right');
            }

            // If the item is more than 1 to the right of the active item, apply the 'hidden-right' class
            if (index > (this.active + 1)) {
                child.classList.remove('hidden-left', 'left', 'active', 'hidden-right');
                child.classList.add('hidden-right');
            }
        })
    }

    // Handle when items get added to the Shadow DOM slot
    // Add to the child elements array and set initial classes
    handleSlotchange(e) {
        this.childElements = e.target.assignedNodes({flatten: false}).filter( (item) => item.nodeName === "DIV" );
        this.setChildProperties();
    }

    render() {
      const isCarousel = this.childElements.length > 1;
        return html`
            <div class="carousel ${this.classes}">
                <section
                    class="carousel-content"
                    @keydown="${this.handleKeyPress}"
                    tabIndex="${isCarousel ? '0' : '-1'}"
                    role="region"
                    aria-live="polite"
                    aria-roledescription="carousel"
                    id="${this.ariaControlId}"
                    aria-label="${this.ariaLabel}">

                    ${this.childElements.length > 1 ? html`
                        <button
                            class="arrow left"
                            @click="${() => this.changeSlide(-1)}"
                            role="button"
                            aria-label="Previous"
                            aria-controls="${this.ariaControlId}"
                            ?disabled=${this.active === 0}>
                            <img alt="" part="arrow-icon" class="arrow-icon" src="${window.constants.cdn}/generic/img/${ window.constants.client }/arrows/arrow-left-white.svg" />
                        </button>` : '' }

                    <slot @slotchange="${this.handleSlotchange}"></slot>

                    ${this.childElements.length > 1 ? html`
                        <button
                            class="arrow right"
                            @click="${() => this.changeSlide(1)}"
                            role="button"
                            aria-label="Next"
                            aria-controls="${this.controlId}"
                            ?disabled=${this.active === this.childElements.length - 1}>
                            <img alt="" part="arrow-icon" class="arrow-icon" src="${window.constants.cdn}/generic/img/${ window.constants.client }/arrows/arrow-right-white.svg" />
                        </button>` : ''}
                </section>

                ${this.childElements.length > 1 ? html`
                    <div class="carousel-thumbnails">
                        ${this.childElements.map( (element, index) => html`
                            <div part="thumbnail${this.active === index ? '-active': ''}" class="thumbnail ${this.active === index ? 'active': ''}"></div>
                        `)}
                    </div>` : ''}
            </div>
        `;
    }
}

customElements.define('carousel-element', CarouselElement);

/**
 * CountdownElement displays a countdown to an event
 */
import {LitElement, html} from 'lit-element';

class CountdownElement extends LitElement {
    
    constructor() {
        super();
        // Holds the interval that runs every second
        this.interval = null;
        // The start time in Date Format
        this.localDate = null;
        // The start time ISO given to the component
        this.startTimeISO = '';
        // The time left split into days, hours, minutes and seconds
        this.time = [];
        // The different sections (e.g. days, hours, minutes, seconds) localised
        this.units = [];
    }

    createRenderRoot() {
        /**
         * Render template without shadow DOM. Note that shadow DOM features like
         * encapsulated CSS and slots are unavailable.
         */
        return this;
    }

    static get properties() {
        return {
            localDate: { type: Number },
            time: { type: Array },
            units: { type: Array },
            startTimeISO: {
                type: String,
                // This is a kludge as there is no good way of telling where
                // this date came from right now and we dont have consistency in
                // the date format being passed about (Date vs String - missing
                // Z and so on).
                //
                // Make sure we have a trailing Z, this is a bit brittle as we
                // dont check for other time zones but at the time of writing
                // all dates are stored as UTC
                converter(value) {
                    return value.slice(-1) === 'Z' ? value : `${value}Z`;
                }
            },
        };
    }

    // On bootup, set the current time and start the countdown
    connectedCallback() {
        super.connectedCallback();
        this.localDate = new Date(this.startTimeISO).getTime();
        this.startTimer();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        clearInterval(this.interval);
    }

    // Finds the difference between browser and server times, split into days/hours/minutes/seconds
    setTimeArray(difference) {
        // Check if time until the event starts
        if (difference >= 0) {
            // Convert ms difference to seconds
            let delta = Math.abs(difference) / 1000;

            // Get Days left
            const days = Math.floor(delta / 86400);
            delta -= (days * 86400);

            // Get Hours left
            const hours = Math.floor(delta / 3600) % 24;
            delta -= (hours * 3600);

            // Get Minutes left
            const minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;

            // Get Seconds left
            const seconds = Math.floor(delta % 60);

            // Store different sections in the array
            this.time = [Math.max(days, 0), Math.max(hours, 0), Math.max(minutes, 0), Math.max(seconds, 0)]
                // Pad single digit numbers with a zero, e.g. 5 -> 05
                .map( (item) => ('0' + item).substr( -(Math.max(`${item}`.length, 2)) ) );
        } else {
            // Reached start time - stop the timer
            clearInterval(this.interval);
        }
    }

    // Start the countdown until reaching the start time
    startTimer() {
        // Get the starting value of the countdown
        this.setTimeArray(this.localDate - new Date());
        // Update every 0.25s to get the new time
        this.interval = setInterval( () => {
            // Get the new time using the time remaining until the start time
            this.setTimeArray(this.localDate - new Date());
        }, 250 );
    }

    render() {
        // Displays the time left in days/hours/minutes/seconds using this.time
        // Each time is separated by a colon
        // Units is the localised copy for days/hours/minutes/seconds
        return html`
            <svg class="countdown-image" viewBox="0 0 720 200" xmlns="http://www.w3.org/2000/svg">
                <text 
                    class="${ this.time[ 0 ] && this.time[ 0 ].length > 2 ? 'digits-3' : '' }"
                    x="12.5%"
                    y="50%"
                    dominant-baseline="middle"
                    text-anchor="middle">${ this.time[ 0 ] }</text>
                <text
                    x="37.5%"
                    y="50%"
                    dominant-baseline="middle"
                    text-anchor="middle">${ this.time[ 1 ] }</text>
                <text
                    x="62.5%"
                    y="50%"
                    dominant-baseline="middle"
                    text-anchor="middle">${ this.time[ 2 ] }</text>
                <text
                    x="87.5%"
                    y="50%"
                    dominant-baseline="middle"
                    text-anchor="middle">${ this.time[ 3 ] }</text>


                <text
                    class="separator"
                    x="25%"
                    y="50%"
                    dominant-baseline="middle"
                    text-anchor="middle">:</text>
                <text
                    class="separator"
                    x="50%"
                    y="50%"
                    dominant-baseline="middle"
                    text-anchor="middle">:</text>
                <text
                    class="separator"
                    x="75%"
                    y="50%"
                    dominant-baseline="middle"
                    text-anchor="middle">:</text>


                <text
                    class="units"
                    x="12.5%"
                    y="80%"
                    dominant-baseline="middle"
                    text-anchor="middle">${ this.units[ 0 ] }</text>
                <text
                    class="units"
                    x="37.5%"
                    y="80%"
                    dominant-baseline="middle"
                    text-anchor="middle">${ this.units[ 1 ] }</text>
                <text
                    class="units"
                    x="62.5%"
                    y="80%"
                    dominant-baseline="middle"
                    text-anchor="middle">${ this.units[ 2 ] }</text>
                <text
                    class="units"
                    x="87.5%"
                    y="80%"
                    dominant-baseline="middle"
                    text-anchor="middle">${ this.units[ 3 ] }</text>
            </svg>
        `;
    }
}

customElements.define('countdown-element', CountdownElement);

/**
 * Analytics Service Class handles sending tracking events to Segment
 * On page load in main.hbs, we set the write key for Segment
 * If the user is logged in we use their auth0 id in the calls
 * otherwise we generate a unique id that we keep in storage to track the user
 */
class AnalyticsService {

    constructor() {
        this.writeKey = '';
    }

    init(key) {
        this.baseProperties = {
            brand_name: window.constants.brand,
            show_name: window.constants.show
        }
        this.setWriteKey(key);
    }

    // Sets the Segment write key and converts to base64
    setWriteKey(key) {
        this.writeKey = window.btoa(`${key}:`);
    }

    // If the user is not logged in, get an anonymous id
    getAnonymousId() {
        const id = localStorage.getItem('segment_anonymous_id');
        if (id) {
            // Use the id if its already in storage
            return id;
        } else {
            // This is a new user - generate a unique id and set to storage
            const newId = `${Date.now()}${Math.floor(Math.random() * 100)}`;
            localStorage.setItem('segment_anonymous_id', newId);
            return newId;
        }
    }

    // Send tracking event to Segment - get the user (logged in or anonymous) and merge given parameters with our base settings
    track(url, properties, other) {
        if (!other.userId) {
            other.anonymousId = other.anonymousId ? other.anonymousId : this.getAnonymousId();
        }
        const mergedProperties = Object.assign({}, this.baseProperties, properties);
        const data = Object.assign({}, other, {properties: mergedProperties});

        fetch( url, {
            method: 'POST',
            headers: {
                'Authorization': `Basic ${this.writeKey}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).catch( (err) => console.log('Could not track event', err));
    }

    // Tracks a page event
    page(properties = {}, other = {}) {
        this.track('https://api.segment.io/v1/page', properties, other);
    }

    // Tracks a user event (link or button clicks etc)
    event(properties = {}, other = {}) {
        this.track('https://api.segment.io/v1/track', properties, other);
    }
}

window.analytics = new AnalyticsService();
/**
 * Copy Class stores copy from l10n so it can be easily accessed by JavaScript components
 */
class Copy {

    constructor() {
        this._static = {};
    }

    get static() {
        return this._static;
    }

    set static( value ) {
        this._static = value;
    }

    getClientInstance ( key ) {
        const item = this.static[ `${ key }_${ window.constants.client }` ] || this.static[ key ];
        return item ? item.replace( new RegExp( '&#x27;', 'g'), `'`) : '';
    }

    onInit( values = {} ) {
        if ( values.static ) {
            this.static = values.static;
        }
    }
}

window.copy = new Copy();
/**
 * Language Service Class handles setting a new language
 * When a new language is set the page is reloaded to get new template content
 */
class LanguageService {

    constructor() {}

    // Sets the new language - the server creates a cookie with the new language
    setLanguage(lang) {
        const data = {
            'language': lang
        };

        fetch('/l10n/set-language', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        })
            // reload the page to get the new localised content
            .then( () => window.location.reload() )
            .catch( (err) => console.log('Could not change language', err) )
    }
}

window.language = new LanguageService();